import { createRouter, createWebHistory } from "vue-router"
import { authGuard } from "@auth0/auth0-vue"
import {
    getSavedLocale,
    setCurrentLocale,
    getDefaultLocale,
    isSupportedLocale
} from "@/util/locate"

const routes = [
    {
        path: "/:lang/:pathMatch(.*)*",
        name: "PathNotFound",
        redirect: "/:lang/home/",
        beforeEnter: authGuard
    },
    {
        path: "/:lang/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "login" */ "@/views/Login"),
        meta: {
            title: "Login"
        }
    },
    {
        path: "/:lang/home",
        component: () => import(/* webpackChunkName: "home" */ "@/views/Home"),
        beforeEnter: authGuard,
        children: [
            {
                path: "overview",
                name: "Overview",
                component: () =>
                    import(
                        /* webpackChunkName: "overview" */ "@/views/Overview"
                    ),
                meta: {
                    title: "Overview"
                }
            },
            {
                path: "booking",
                name: "Booking",
                component: () =>
                    import(
                        /* webpackChunkName: "booking" */ "@/views/Booking.vue"
                    ),
                meta: {
                    title: "Booking"
                }
            },
            {
                path: "upcomingbooking",
                name: "Upcomingbooking",
                component: () =>
                    import(
                        /* webpackChunkName: "upcoming_booking" */ "@/views/UpcomingBooking"
                    ),
                meta: {
                    title: "Upcoming Booking"
                }
            },
            {
                path: "pastbooking",
                name: "Pastbooking",
                component: () =>
                    import(
                        /* webpackChunkName: "past_booking" */ "@/views/PastBooking"
                    ),
                meta: {
                    title: "Past Booking"
                }
            },
            {
                path: "room-availability",
                name: "RoomAvailability",
                component: () =>
                    import(
                        /* webpackChunkName: "room_availability" */ "@/views/RoomAvailability.vue"
                    ),
                meta: {
                    title: "Room Availability"
                }
            },
            {
                path: "room-pricing",
                name: "RoomPricing",
                component: () =>
                    import(
                        /* webpackChunkName: "room_pricing" */ "@/views/RoomPricing.vue"
                    ),
                meta: {
                    title: "Room Pricing"
                }
            },
            {
                path: "self-check-in-key",
                name: "Doorlock",
                component: () =>
                    import(
                        /* webpackChunkName: "doorlock" */ "@/views/DoorLock.vue"
                    ),
                meta: {
                    title: "Self Check-in Key"
                }
            },
            {
                path: "setting",
                name: "Setting",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/Setting"
                    ),
                meta: {
                    title: "Setting"
                }
            },
            {
                path: "partyroom/dashboard",
                name: "PDashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "party_dashboard" */ "@/views/party_room/Dashboard"
                    ),
                meta: {
                    title: "Party Room Dashboard"
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active"
})

router.beforeEach(async (to, from, next) => {
    if (to.params.role) {
        let role = to.params.role
        if (
            parseInt(role) === 1 &&
            to.name !== "Booking" &&
            to.name !== "Upcomingbooking" &&
            to.name !== "Pastbooking" &&
            to.name !== "Setting"
        ) {
            await router.push({
                name: "Upcomingbooking",
                params: { role: role }
            })
        }
    }
    const savedLang = getSavedLocale()
    const urlLang = to.path.split("/")[1]
    if (isSupportedLocale(urlLang)) {
        setCurrentLocale(urlLang)
        next()
    } else {
        const path = to.path.slice(to.path.indexOf("/", 1))
        let translatedPath = "/" + getDefaultLocale() + path
        if (isSupportedLocale(savedLang)) {
            translatedPath = "/" + savedLang + path
        }
        return next({ path: translatedPath })
    }
})

export default router
